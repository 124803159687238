/**
 * KKH Versicherungsverhaeltnis
 * This is a service wich allows users to clarify their insurance relationship.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileUploadBitgo } from './file-upload';
import { StudyBitgo } from './study';
import { UnemploymentBitgo } from './unemployment';
import { OtherStatusBitgo } from './other-status';
import { EmploymentBitgo } from './employment';


export interface InsuranceRelationshipBodyBitgo { 
    /**
     * Type of situation of the customer:   * employed - angestellt   * self_employed - selbstständig / in einer Existenzgründung   * unemployed - arbeitslos   * student - in einem Studium   * other - in einer anderen Situation 
     */
    situation?: InsuranceRelationshipBodyBitgo.SituationBitgo;
    employment?: EmploymentBitgo;
    unemployment?: UnemploymentBitgo;
    study?: StudyBitgo;
    otherStatus?: OtherStatusBitgo;
    /**
     * Contains all documents to clarify the insurance relationship.
     */
    documents?: Array<FileUploadBitgo>;
    /**
     * Have you taken note of our Privacy Policy?
     */
    takenNoteOfPrivacyPolicy?: boolean;
}
export namespace InsuranceRelationshipBodyBitgo {
    export type SituationBitgo = 'EMPLOYED' | 'SELF_EMPLOYED' | 'UNEMPLOYED' | 'STUDENT' | 'OTHER';
    export const SituationBitgo = {
        Employed: 'EMPLOYED' as SituationBitgo,
        SelfEmployed: 'SELF_EMPLOYED' as SituationBitgo,
        Unemployed: 'UNEMPLOYED' as SituationBitgo,
        Student: 'STUDENT' as SituationBitgo,
        Other: 'OTHER' as SituationBitgo
    };
}


